<template>
  <v-container>
    <div class="mb-4 pl-4">
      <v-btn
        v-if="!proveedorSinSolicitudes"
        @click.stop="visualizarAdjuntoOrdenCompra()"
        color="primary"
        class="d-flex flex-row-reverse"
        :loading="visualizacionAdjuntoOrdenCompra.isLoading"
      >
        <v-icon class="white--text mr-2">mdi mdi-text-box-search</v-icon>
        Orden de compra
      </v-btn>
    </div>
    <v-card class="px-4 pb-4">
      <v-card-title class="d-flex align-center">
        <v-icon>mdi-gesture-tap</v-icon>
        <h5 class="mb-0 ml-2" style="word-break: break-word">
          Acciones rápidas.
        </h5>
      </v-card-title>

      <!-- <div class="mt-4 mx-auto mb-4"> -->
      <EmptyComponent :empty="sinAccionesRapidas" colored>
        <template v-slot:empty-content>
          <v-icon x-large>mdi-hand-back-right-off-outline</v-icon>
          <p class="mb-0 mt-2 font-weight-bold text-body-1">
            No se encuentran acciones rápidas.
          </p>
        </template>
        <div class="d-flex flex-wrap mt-2" style="gap: 10px">
          <v-btn
            v-for="(boton, index) in botones.data"
            :key="index"
            color="primary"
            small
            @click="abrirAcciones(boton)"
          >
            {{ boton.accion_requerida_plural }}
          </v-btn>
        </div>
      </EmptyComponent>
    </v-card>
    <v-dialog v-model="isFormModalOpen" max-width="600px">
      <component
        v-if="activeStep"
        :is="`${activeStep.item.formulario}-form`"
        @on-save="guardar"
        @on-cancel="isFormModalOpen = false"
      />
    </v-dialog>
    <SpinnerComponent
      :spinning="cargandoSolicitudes"
      text="Cargando solicitudes..."
    />

    <div class="mt-4 mx-auto mb-4">
      <EmptyComponent
        :empty="proveedorSinSolicitudes"
        text="Aún no posee solicitudes."
        colored
      />
    </div>
    <ItemList :statuses="statuses.data" :items="productItems" />

    <PdfModal
      :isOpen="modalVisualizacionAdjuntoOrden"
      :source-loadable="visualizacionAdjuntoOrdenCompra"
      @on-visibility-change="manejarVisibilidadModalVisualizacionAdjuntoOrden"
      filename="adjunto-orden"
    />

    <ConfirmationModalComponent
      :isOpen="confirmationModal.isOpen"
      :description="confirmationModal.description"
      :isLoading="alterar.isLoading"
      @confirm="alterarEstado"
      @cancel="clearConfirmation"
    />
  </v-container>
</template>
<script>
import {
  AdjuntoForm,
  CantidadAdjuntoForm,
  CantidadForm,
  ComentarioForm,
  FechaForm,
  ComentarioAdjuntoForm,
  FechaAdjuntoForm,
} from "@/components/CMForms";

import {
  createLoadable,
  createPageable,
  toggleLoadable,
  togglePageable,
  setLoadableResponse,
  setPageableResponse,
  PAGINATION_PROPERTY_NAMES,
  isResponseSuccesful,
} from "@/utils/loadable.js";
import ItemList from "./components/ItemList.vue";
import { EmptyComponent, SpinnerComponent } from "@/components/utils";
import PdfModal from "@/components/PdfModal.vue";
import { ConfirmationModalComponent } from "@/components/utils";
import { convertToFormData, removePropsFromObjectIfNil } from "@/utils/data";

const defaultPagination = {
  [PAGINATION_PROPERTY_NAMES.PAGE_SIZE]: 10,
  [PAGINATION_PROPERTY_NAMES.CURRENT_PAGE]: 1,
};

const initialConfirmationModal = {
  isOpen: false,
  description: "",
  data: null,
};

export default {
  components: {
    ItemList,
    EmptyComponent,
    SpinnerComponent,
    PdfModal,
    ConfirmationModalComponent,
    AdjuntoForm,
    CantidadAdjuntoForm,
    CantidadForm,
    ComentarioForm,
    FechaForm,
    ComentarioAdjuntoForm,
    FechaAdjuntoForm,
  },
  data: () => ({
    statuses: createLoadable([]),
    botones: createLoadable([]),
    alterar: createLoadable([]),
    activeTab: "pendientes",
    requestId: null,
    // data
    pendingItems: createPageable([], 10),
    productItems: createPageable([], 10),
    seenItems: createPageable([], 10),
    isPreviewModalOpen: false,
    ordenActiva: null,
    activeStep: null,
    modalVisualizacionAdjuntoOrden: false,
    visualizacionAdjuntoOrdenCompra: createLoadable(null),
    isFormModalOpen: false,
    confirmationModal: initialConfirmationModal,
    dataGuardada: null,
  }),

  computed: {
    cargandoSolicitudes() {
      return this.productItems.isLoading;
    },
    proveedorSinSolicitudes() {
      return (
        !this.productItems.isLoading && this.productItems.data.length === 0
      );
    },
    sinAccionesRapidas() {
      return !this.botones.isLoading && this.botones.data.length === 0;
    },
  },
  methods: {
    abrirAcciones(item, msg) {
      this.isFormModalOpen = false;
      if (item.formulario) {
        this.confirmationModal = {
          description:
            msg ?? `¿Desea ${item.accion_requerida_plural} esta solicitud?`,
          data: removePropsFromObjectIfNil(item, ["comentario", "adjunto"]),
          item: item,
        };
        this.isFormModalOpen = true;
        this.activeStep = this.confirmationModal;
      } else {
        this.confirmationModal = {
          isOpen: true,
          description:
            msg ?? `¿Desea ${item.accion_requerida_plural} esta solicitud?`,
          data: removePropsFromObjectIfNil(item, ["comentario", "adjunto"]),
          item: item,
        };
        this.activeStep = this.confirmationModal;
      }
    },

    async listarBotones() {
      toggleLoadable(this.botones);
      const { data } =
        await this.services.cmOrdenesCompra.obtenerBotonesOrdenCompra(
          this.$route.params.id
        );
      setLoadableResponse(this.botones, data);
    },

    async alterarEstado() {
      toggleLoadable(this.alterar);
      const formData = convertToFormData({
        ...this.dataGuardada,
        id_secuencia: this.activeStep.item.id_secuencia,
      });
      const { data } = await this.services.cmOrdenesCompra.alterarEstadoCompra(
        this.$route.params.id,
        formData
      );
      setLoadableResponse(this.alterar, data);
      if (isResponseSuccesful(data)) {
        const filters = {
          per_page: 10,
          page: 1,
        };
        this.confirmationModal.isOpen = false;

        this.cargarListaProducto(filters);
        this.listarBotones();
      }
    },

    async guardar(data) {
      this.isFormModalOpen = false;
      this.confirmationModal.isOpen = true;
      this.dataGuardada = data;
    },

    async fetchStatuses() {
      toggleLoadable(this.statuses);
      const { data } = await this.services.cmSupplierApproval.getStatusList();
      setLoadableResponse(this.statuses, data);
    },
    async cargarListaProducto(filters) {
      const filtros = {
        ...filters,
        id_orden_compra: this.$route.params.id,
      };
      togglePageable(this.productItems);
      const { data, headers } =
        await this.services.cmSupplierApproval.obtenerProductoOrden({
          pagination: true,
          ...filtros,
        });
      setPageableResponse(this.productItems, data, headers);
    },
    manejarVisibilidadModalVisualizacionAdjuntoOrden(visible) {
      this.modalVisualizacionAdjuntoOrden = visible;
    },
    async visualizarAdjuntoOrdenCompra() {
      this.modalVisualizacionAdjuntoOrden = true;
      const idProveedor = this.productItems.data[0].id_proveedor;
      const solicitudCompra = this.productItems.data[0].CMSolicitudCompra.id;

      toggleLoadable(this.visualizacionAdjuntoOrdenCompra);
      const { data } =
        await this.services.cmOrdenesCompra.visualizarAdjuntoOrden(
          solicitudCompra,
          { id_proveedor: idProveedor }
        );
      setLoadableResponse(this.visualizacionAdjuntoOrdenCompra, data, {
        isFile: true,
      });
    },
    clearConfirmation() {
      this.confirmationModal = initialConfirmationModal;
    },
  },
  async created() {
    this.listarBotones();
    // this.fetchStatuses();
    // this.loadSeenItems(defaultPagination);
    this.cargarListaProducto(defaultPagination);
    this.requestId = this.$route.params.id;
  },
};
</script>
